<!--
 * @Description:
 * @Version: 2.0
 * @Author: 隐世浮华
 * @Date: 2023-08-01 10:34:31
 * @LastEditors: 隐世浮华
 * @LastEditTime: 2023-09-04 09:14:43
 * @FilePath: \edub_tob_pc\src\views\newPactivity.vue
-->
<template>
  <div class="mainbox">
    <div class="mtop">
      <img style="width: 100%; position: absolute; z-index: 1" src="@/assets/img/xinren/bgimg.png" alt="">
      <div class="mtop_middle">
        <img class="mtop_title" src="~@/assets/img/xinren/title.png">
        <div class="mtop_botimg">
          <img class="mtop_botimg_left" src="~@/assets/img/xinren/ren1.png">
          <img class="mtop_botimg_right" src="~@/assets/img/xinren/ren2.png">
        </div>
      </div>
    </div>

    <div class="main_middle">
      <div> <img class="tailuo" src="~@/assets/img/xinren/671.png"> </div>
      <div> <img class="leiou" src="~@/assets/img/xinren/672.png"></div>
      <div> <img class="saiwen" src="~@/assets/img/xinren/673.png"></div>
      <div><img class="jieke" src="~@/assets/img/xinren/674.png"></div>
      <div class="new_p_gift">
        <img src="~@/assets/img/xinren/libaotext.png">
        <div class="gift_all">
          <Swiper v-if="discountCoupon.length > 0 || datas.integral > 0" style="width: 980px;margin: 0 auto;"
            :options="swiperOption">
            <SwiperSlide v-for="(item, index) in discountCoupon" :key="index" style="width: 300px; height: 271px;">
              <div v-if="item.couponType == 1" class="img2">
                <span class="title">优惠券</span>
                <div class="jine"><span>￥</span>{{ item.couponValue }}</div>
                <img class="jianji" src="~@/assets/img/xinren/1601.png">
                <div class="beizhu">全场无门槛使用</div>
              </div>
              <div v-if="item.couponType == 2" class="img2">
                <span class="title">满减券</span>
                <div class="jine"><span>￥</span>{{ item.couponValue }}</div>
                <img class="jianji" src="~@/assets/img/xinren/1601.png">
                <div class="beizhu">全场满{{ item.fullReductionAmount }}使用</div>
              </div>
              <div v-if="item.couponType == 3" class="img2">
                <span class="title">折扣券</span>
                <div class="jine">{{ item.fullDiscountRate / 10 }}<span>折</span></div>
                <img class="jianji" src="~@/assets/img/xinren/1601.png">
                <div class="beizhu">全场无门槛使用</div>
              </div>
            </SwiperSlide>
            <SwiperSlide v-if="datas.integral > 0" style="width: 300px; height: 271px;">
              <div v-if="datas.integral > 0" class="img2" src="~@/assets/img/xinren/juan.png">
                <span class="title1">商城积分</span>
                <div class="jine">{{ datas.integral }}<span>积分</span></div>
                <img class="jianji" src="~@/assets/img/xinren/1601.png">
                <div class="beizhu">请前往积分商城使用</div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <img v-if="datas.status == 2" class="img5" src="~@/assets/img/xinren/guoqi.png" @click="guoqilingqu">
        <img v-else-if="datas.couponStatus == 0" class="img5" src="~@/assets/img/xinren/lingqu.png" @click="lingqu">
        <img v-else-if="datas.couponStatus == 1" class="img5" src="~@/assets/img/xinren/yiling.png" @click="yilingqu">
      </div>
      <div id="box1-id" class="knowledge_package">

        <div v-if="majorList.length > 0" id="box2-id" class="img1">
          <div class="left">
            <div class="title">
              <span>优惠抢课</span>
              <div>直达</div>
            </div>
            <div class="kind-list">
              <div v-for="(item, index) in majorList" :key="index" class="kind-item"
                :class="search.kind == item.bigKind ? 'active' : ''" @click="school(item)">
                {{ item.pname }}
              </div>
            </div>
          </div>
          <div class="totop" @click="toTop">返回顶部</div>
        </div>
        <div class="kecheng">
          <div class="img2">
            <template v-if="RecommendClassList.length > 0">
              <div class="title"> <span>{{ search.kindName }}</span> </div>
              <div class="right">
                <div v-for="(item, index) in RecommendClassList" :key="index" class="contentItem">
                  <div class="imgItem"><el-image :src="item.img" class="imgClass" fit="cover" /></div>
                  <div class="item_content">
                    <div class="titleItem twoEllipsis">{{ item.tiitle ? item.tiitle : item.Title }}</div>
                    <div class="jiage">
                      <div class="new"><span>￥</span>{{ item.price }}</div>
                      <del class="old">原价￥{{ item.olderPrice }}</del>
                    </div>
                    <div :class="datas.status == 2 || item.status == 1 ? 'guoqibao' : 'baoming'"
                      @click="getCourse(item, item.type)">
                      <span>立即</span>
                      <span>报名</span>

                    </div>
                  </div>
                </div>
              </div>
              <div class="bot" />
            </template>
          </div>
          <div class="img3">
            <template v-if="docPackageDtoVos.length > 0">
              <div class="title"> <span>免费资料包</span> </div>
              <div class="right">
                <div v-for="(item, index) in docPackageDtoVos" :key="index" class="contentItem">
                  <div class="imgItem"><el-image :src="item.img" class="imgClass" fit="cover" /></div>
                  <div class="item_content">
                    <div class="titleItem twoEllipsis">{{ item.title }}</div>
                    <div class="jiage">
                      <div class="ziliao"><span>共{{ item.docNum }}份资料</span></div>
                      <div class="new"><span class="span1">新人专享：</span><span class="span2">￥</span>{{ item.price }}
                      </div>
                    </div>
                    <div :class="datas.status == 2 || item.status == 1 ? 'guoqibao' : 'baoming'"
                      @click="getCourse(item, 4)">
                      <span>立即</span>
                      <span>报名</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bot" />
            </template>
          </div>
          <div class="img4">
            <template v-if="questionPackageDtoVos.length > 0">
              <div class="title"> <span>免费题库包</span> </div>
              <div class="right">
                <div v-for="(item, index) in questionPackageDtoVos" :key="index" class="contentItem">
                  <div class="imgItem"><el-image :src="item.image" class="imgClass" fit="cover" /></div>
                  <div class="item_content">
                    <div class="titleItem twoEllipsis">{{ item.name }}</div>
                    <div class="jiage">
                      <div class="ziliao"><span>共{{ item.paperNum }}份资料</span></div>
                      <div class="new"><span class="span1">新人专享：</span><span class="span2">￥</span>{{ item.packagePrice }}
                      </div>
                    </div>
                    <div :class="datas.status == 2 || item.status == 1 ? 'guoqibao' : 'baoming'"
                      @click="getCourse(item, 5)">
                      <span>立即</span>
                      <span>报名</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bot" />
            </template>
          </div>
        </div>
      </div>
      <div id="box3-id" class="guize">
        <div class="text">
          <div class="div1">
            <div><span>1</span> </div>活动时间: 自注册之日起{{ validDay }}天内领取，下单立减大额红包， 红包不与新老优惠同享。
          </div>
          <div class="div2">
            <div> <span>2</span> </div>下单立减红包60-2000元，不同考试班级立减红包额度不同，请仔细查看使用的满减门槛。
          </div>
          <div class="div3">
            <div> <span>3</span> </div>您领取的内容将在个人中心、学习中心生成，请及时查看。
          </div>
          <div class="div3">
            <div> <span>4</span> </div>在法律允许范围内，活动解释权归聚学U所有。
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- <footers /> -->
</template>
<script>
import footers from '../components/footersSeventhNew.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import { getInfo, getDomain } from '@/api/cookies'
import 'swiper/swiper-bundle.css'
// import 'swiper/css/swiper.css'
import { Know } from '@/api/know'
const know = new Know()
import { getInfo } from '@/api/cookies'
export default {
  components: {
    footers,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        // 设置swiper选项
        direction: 'horizontal',
        initialSlide: 1,
        observeParents: true,
        observer: true,
        slidesPerView: 3,
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 3000, // 自动切换的延迟时间，单位为毫秒
          disableOnInteraction: false // 用户交互后是否停止自动轮播，默认为 true
        }
      },
      discountCoupon: [],
      datas: [],
      majorList: [],
      search: {
        schoole: null,
        kindList: null,
        kind: 1,
        kindName: '统招专升本'
      },
      validDay: null,
      docPackageDtoVos: [], // 资料
      questionPackageDtoVos: [], // 题库
      RecommendClassList: [], // 课程
      userInfo: {}
    }
  },
  async created() {
    this.userInfo = getInfo()
    console.log('已运行');
    await this.getPcPackageTypeList()
  },
  mounted() {

  },
  methods: {
    getPcPackageTypeList() {
      know.getPcPackageTypeList().then((res) => {
        this.majorList = res.data.typeListVo
        if (this.majorList.length > 0) {
          this.$nextTick(() => {
            window.addEventListener('scroll', this.calculateDistance)
            const box2 = document.getElementById('box2-id')

            box2.style.top = '9999px'
            box2.style.bottom = `auto`
          })
          this.search.kind = this.majorList[0].bigKind
        }
        this.getPcPackageByTenant()
        this.$forceUpdate()
      })
    },
    calculateDistance() {
      const screenHeight = window.innerHeight
      const box1 = document.getElementById('box1-id')
      const box2 = document.getElementById('box2-id')
      const distanceToTop1 = box1.offsetTop
      const height = box1.offsetHeight
      const distanceToBottom2 = distanceToTop1 + height
      const rect = box1.getBoundingClientRect()
      const distanceToTop = rect.top
      const scrollDistance = window.pageYOffset
      if (screenHeight > distanceToTop) {
        if (distanceToTop < 0) {
          if (distanceToBottom2 < `${scrollDistance + 720}`) {
            //  
            box2.style.bottom = `450px`
            box2.style.top = `auto`
          } else {
            box2.style.top = `0px`
            box2.style.bottom = `auto`
          }
        } else {
          box2.style.top = `${distanceToTop}px`
          box2.style.bottom = `auto`
        }
      } else {
        box2.style.top = '9999px'
        box2.style.bottom = `auto`
      }
    },

    getPcPackageByTenant() {
      know.getPcPackageByTenant(this.search.kind).then((res) => {
        this.datas = res.data
        if (res.data.discountCoupon) {
          this.discountCoupon = res.data.discountCoupon
          this.$forceUpdate()
        } else {
          this.discountCoupon = []
        }
        if (res.data.classesDtoVos) {
          this.RecommendClassList = res.data.classesDtoVos
          this.RecommendClassList.forEach((e) => {
            e.type = 1
          })
          this.$forceUpdate()
        } else {
          this.RecommendClassList = []
        }
        if (res.data.courseList) {
          this.RecommendClassList = this.RecommendClassList.concat(res.data.courseList)
        }
        if (res.data.docPackageDtoVos) {
          this.docPackageDtoVos = res.data.docPackageDtoVos
        } else {
          this.docPackageDtoVos = []
        }
        if (res.data.questionPackageDtoVos) {
          this.questionPackageDtoVos = res.data.questionPackageDtoVos
        } else {
          this.questionPackageDtoVos = []
        }
        this.validDay = res.data.validDay
        this.$forceUpdate()
      })
    },

    yilingqu() {
      this.$message({
        message: '您已经领取过了！',
        type: 'warning'
      })
    },
    guoqilingqu() {
      this.$message({
        message: '活动已过期！',
        type: 'warning'
      })
    },
    lingqu() {
      const userInfo = getInfo()
      if (!userInfo) {
        this.$message({
          message: '请您先登录哦~',
          type: 'warning'
        })

        return
      }
      know.getPackageByNewUser(this.datas.id).then((res) => {
        this.getPcPackageByTenant()
        this.$message({
          message: res.msg,
          type: 'success'
        })
      })
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 使用平滑滚动效果
      })
    },
    async school(val) {
      if (val) {
        this.search.kindList = val
        this.search.kindName = val?.pname
        this.search.kind = val?.bigKind
      } else {
        this.search.kind = null
      }
      await this.getPcPackageByTenant()
      const boxElement = document.getElementById('box1-id')
      boxElement.scrollIntoView({ behavior: 'smooth' })
    },

    getCourse(val, type) {
      const userInfo = getInfo()
      if (!userInfo) {
        this.$message({
          message: '请您先登录哦~',
          type: 'warning'
        })
        return
      }
      know.getCourseByNewUser(val.id, this.datas.id, type, val.validity ? val.validity : undefined, val.img ? val.img : undefined).then((res) => {
        this.getPcPackageByTenant()
        if (res.msg == '领取成功') {
          this.$message({
            message: '恭喜您，您已领取成功',
            type: 'success'
          })
        } else { this.$message(res.msg) }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.mainbox {
  width: 100%;
  min-height: 700px;
  background: rgba(255, 81, 49, 1);
  position: relative;
  padding-bottom: 97px;
  overflow: hidden;

  .mtop {
    width: 100%;
    position: relative;
    background: rgba(255, 81, 49, 1);

    .mtop_middle {
      position: relative;
      width: 1100px;
      margin: 0 auto;
      z-index: 9999;
      padding-top: 120px;

      .mtop_title {
        width: 812px;
        display: block;
        margin: 0 auto;
        animation: move1 2s linear infinite alternate both;
      }

      .mtop_botimg {
        width: 888px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        margin-top: -50px;

        .mtop_botimg_left {
          display: block;
          width: 429px;
          animation: move1 3s linear infinite alternate both;
        }

        .mtop_botimg_right {
          display: block;
          width: 315px;
          animation: move2 3s linear infinite alternate both;
        }
      }
    }
  }

  .main_middle {
    width: 1100px;
    margin: 0 auto;

    // position: relative;
    .tailuo {
      width: 195px;
      height: 286px;
      position: absolute;
      top: 900px;
      right: 0px;
    }

    .leiou {
      width: 177px;
      height: 197px;
      position: absolute;
      top: 1435px;
      left: -1px;
    }

    .saiwen {
      width: 106px;
      height: 273px;
      position: absolute;
      top: 1900px;
      right: 0;
    }

    .jieke {
      width: 65px;
      height: 154px;
      position: absolute;
      top: 2468px;
      left: 0;
    }

    .new_p_gift {
      width: 100%;
      height: 541px;
      background-image: url("~@/assets/img/xinren/kuang.png");
      background-size: 100% 100%;

      >img {
        display: block;
        margin: 0 auto;
        position: relative;
        top: -34px;
      }

      .gift_all {
        width: 100%;
        height: 272px;
        display: flex;

        .img2 {
          width: 299px;
          height: 271px;
          margin-left: 20px;
          background-image: url("~@/assets/img/xinren/juan.png");
          display: flex;
          align-items: center;
          flex-direction: column;

          .title {
            font-size: 22px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #feeace;
            margin-left: 5px;
            margin-top: 4px;
          }

          .title1 {
            font-size: 22px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #feeace;
            margin-left: 8px;
            margin-top: 4px;
          }

          .jine {
            span {
              font-size: 26px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #fd5b23;
            }

            font-size: 70px;
            font-family: Microsoft YaHei-Bold,
            Microsoft YaHei;
            font-weight: bold;
            color: #fd5b23;
            line-height: 20px;
            text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
            margin-top: 66px;
          }

          .jianji {
            width: 50px;
            height: 52px;
            margin-top: 45px;
            animation: jump 2s infinite;
          }

          .beizhu {
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin-top: 8px;
          }
        }
      }

      .img5 {
        top: 0 !important;
        display: block;
        width: 288px;
        height: 65px;
        position: relative;
        margin: 0 auto;
        animation: move3 0.8s linear infinite alternate both;
        margin-top: 42px;
        cursor: pointer;
      }
    }

    .knowledge_package {
      // display: flex;
      margin-top: 35px;
      position: relative;
      // justify-content: space-between;
      padding-left: 269px;

      .img1 {
        width: 255px;
        height: 720px;
        background-image: url("~@/assets/img/xinren/navigation.png");
        background-size: 100% 100%;
        z-index: 99;
        // margin-left: -20px;
        position: fixed;
        top: 50px;
        /* 根据上层盒子的位置进行调整 */
        // left: 0px; /* 根据上层盒子的位置进行调整 */
        left: calc((100% - 1115px) / 2);

        .left {
          margin-left: 60px;
          padding-top: 55px;

          .title {
            // margin-top: 55px;

            span {
              font-size: 34px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #fd5b23;
            }

            div {
              margin-left: 33px;
              font-size: 34px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #fd5b23;
            }
          }

          .kind-list {
            // width: 618px;
            margin-top: 22px;
            display: flex;
            flex-direction: column;

            .kind-item {
              margin-right: 22px;
              margin-top: 5px;
              position: relative;
              cursor: pointer;
              font-size: 24px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 68px;
            }

            .active {
              width: 190px;
              height: 68px;
              background: linear-gradient(89deg, #ffa403 0%, #ff5f11 100%);
              border-radius: 6px 6px 6px 6px;
              opacity: 1;
              border: 2px solid rgba(255, 255, 255, 0.2);
              padding-left: 25px;
              position: relative;
              right: 28px;
            }
          }
        }

        .totop {
          font-size: 26px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #fd5b23;
          position: absolute;
          // top: 65px;
          left: 76px;
          bottom: 14px;
          cursor: pointer;
        }
      }

      .kecheng {
        // margin-left: 269px;
        // padding-left: 269px;
        margin-left: -20px;

        .img2 {
          width: 840px;
          // height: 1471px;
          // background-image: url("~@/assets/img/xinren/zikao.png");
          background-size: 100% 100%;

          .title {
            position: relative;
            width: 100%;
            height: 132px;
            text-align: center;
            // top: 15px;
            // left: 340px;
            font-size: 36px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #fcd39d;
            background-image: url("~@/assets/img/xinren/z21148.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;

            span {
              position: relative;

              top: 15px;
              // left: 340px;
            }
          }

          .right {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            position: relative;
            background-image: url("~@/assets/img/xinren/z21150.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            // top: 99px;
            // left: 46px;
            padding: 30px;
            padding-bottom: 0;
            margin-top: -24px;

            .contentItem {
              float: left;
              width: 372px;
              height: 415px;
              background: linear-gradient(1deg, #fcf8f2 0%, #fef0db 100%);
              border-radius: 10px 10px 10px 10px;
              opacity: 1;
              cursor: pointer;
              margin: 0 22px 18px 0px;
              transition: all 500ms;
              padding: 15px;

              &:hover {
                transform: translateY(-10px);

                .imgClass {
                  transform: scale(1.1);
                }

                box-shadow: 0px 1px 7px 7px rgba(207, 207, 207, 0.1);
              }

              .imgItem {
                width: 340px;
                height: 195px;
                border-radius: 10px;
                overflow: hidden;
                opacity: 1;

                .imgClass {
                  width: 100%;
                  height: 195px;
                  transition: all 500ms;
                }
              }

              .item_content {
                width: 340px;
                position: relative;
                display: flex;
                padding: 15px 0px 0px;
                flex-direction: column;
                justify-content: space-between;
                height: 156px;

                .titleItem {
                  font-size: 24px;
                  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                  font-weight: 400;
                  color: #333333;
                  margin-top: 7px;
                  flex-shrink: 0;
                }

                .jiage {
                  position: relative;
                  top: 30px;

                  .new {
                    font-size: 34px;
                    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                    font-weight: bold;
                    color: #f23239;
                    margin-bottom: 5px;

                    span {
                      font-size: 24px;
                      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                      font-weight: 400;
                      color: #f23239;
                    }
                  }

                  .old {
                    font-size: 20px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                  }
                }

                .guoqibao {
                  width: 88px;
                  height: 88px;
                  background: #b6b6b6;
                  box-shadow: 0px 3px 10px 1px rgba(177, 177, 177, 0.5);
                  border-radius: 20px;
                  opacity: 1;
                  position: absolute;
                  left: 253px;
                  top: 100px;
                  padding-top: 8px;
                  padding-left: 18px;

                  span {
                    font-size: 26px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                  }
                }

                .baoming {
                  width: 88px;
                  height: 88px;
                  background: #f13239;
                  box-shadow: 0px 3px 10px 1px rgba(241, 50, 57, 0.5);
                  border-radius: 10px;
                  opacity: 1;
                  position: absolute;
                  left: 253px;
                  top: 100px;
                  padding-top: 8px;
                  padding-left: 18px;

                  span {
                    font-size: 26px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                  }
                }
              }
            }

            .contentItem:nth-child(2n - 1) {
              margin-left: 6px;
            }

            .contentItem:nth-child(2n) {
              margin-right: 0px;
            }
          }

          .bot {
            width: 100%;
            height: 71px;
            background-image: url("~@/assets/img/xinren/z21149.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-top: -25px;
          }

          .saiwen {
            width: 106px;
            height: 273px;
            position: absolute;
            top: 668px;
            right: -395px;
          }
        }

        .img3 {
          width: 840px;
          // height: 997px;
          // background-image: url("~@/assets/img/xinren/ziliao.png");
          // background-size: 100% 100%;
          margin-top: 40px;

          .title {
            position: relative;
            width: 100%;
            height: 132px;

            text-align: center;
            // top: 15px;
            // left: 340px;
            font-size: 36px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #fcd39d;
            background-image: url("~@/assets/img/xinren/z21148.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;

            span {
              position: relative;

              top: 15px;
              // left: 340px;
            }
          }

          .right {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            position: relative;
            // top: 130px;
            // left: 46px;
            background-image: url("~@/assets/img/xinren/z21150.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;

            padding: 30px;
            padding-bottom: 0;
            margin-top: -24px;

            .contentItem {
              float: left;
              width: 372px;
              height: 400px;
              background: linear-gradient(1deg, #fcf8f2 0%, #fef0db 100%);
              border-radius: 10px 10px 10px 10px;
              opacity: 1;
              cursor: pointer;
              margin: 0 22px 18px 0px;
              transition: all 500ms;
              padding: 15px;

              &:hover {
                transform: translateY(-10px);

                .imgClass {
                  transform: scale(1.1);
                }

                box-shadow: 0px 1px 7px 7px rgba(207, 207, 207, 0.1);
              }

              .imgItem {
                width: 340px;
                height: 195px;
                border-radius: 10px;
                overflow: hidden;
                opacity: 1;

                .imgClass {
                  width: 100%;
                  height: 195px;
                  transition: all 500ms;
                }
              }

              .item_content {
                width: 340px;
                position: relative;
                display: flex;
                padding: 2px 0px 0px;
                flex-direction: column;
                justify-content: space-between;
                height: 156px;

                .titleItem {
                  font-size: 24px;
                  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                  font-weight: 400;
                  color: #333333;
                  margin-top: 7px;
                  flex-shrink: 0;
                }

                .jiage {
                  position: relative;
                  top: 20px;

                  .ziliao {
                    width: 94px;
                    height: 30px;
                    // background: #fae5cd;
                    background-image: url("~@/assets/img/xinren/lh155.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    border-radius: 21px 21px 21px 21px;
                    opacity: 1;
                    padding-left: 10px;
                    padding-top: 2px;

                    span {
                      width: 73px;
                      height: 19px;
                      font-size: 14px;
                      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                      font-weight: 400;
                      color: #a45900;
                    }
                  }

                  .new {
                    font-size: 28px;
                    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                    font-weight: bold;
                    color: #f23239;
                    margin-bottom: 5px;

                    .span1 {
                      font-size: 18px;
                      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                      font-weight: 400;
                      color: #f23239;
                    }

                    .span2 {
                      font-size: 13px;
                      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                      font-weight: 400;
                      color: #f23239;
                    }
                  }
                }

                .guoqibao {
                  width: 122px;
                  height: 51px;
                  background: #b6b6b6;
                  box-shadow: 0px 3px 10px 1px rgba(177, 177, 177, 0.5);
                  border-radius: 10px;
                  opacity: 1;
                  position: absolute;
                  left: 220px;
                  top: 130px;
                  padding-top: 6px;
                  padding-left: 11px;

                  span {
                    font-size: 26px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                  }
                }

                .baoming {
                  width: 122px;
                  height: 51px;
                  background: #f13239;
                  box-shadow: 0px 3px 10px 1px rgba(241, 50, 57, 0.5);
                  border-radius: 10px 10px 10px 10px;
                  opacity: 1;
                  position: absolute;
                  left: 220px;
                  top: 120px;
                  padding-top: 6px;
                  padding-left: 11px;

                  span {
                    font-size: 26px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                  }
                }
              }
            }

            .contentItem:nth-child(2n - 1) {
              margin-left: 6px;
            }

            .contentItem:nth-child(2n) {
              margin-right: 0px;
            }

            .shangyiye {
              width: 20px;
              height: 5px;
              background: #fff;
              position: relative;
              top: 416px;
              left: -444px;
              margin-left: 5px;
            }

            .xiayiye {
              width: 20px;
              height: 5px;
              background: #fff;
              position: relative;
              top: 416px;
              left: -414px;
            }
          }

          .bot {
            width: 100%;
            height: 71px;
            background-image: url("~@/assets/img/xinren/z21149.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-top: -25px;
          }

          .jieke {
            width: 65px;
            height: 154px;
            position: absolute;
            top: 1468px;
            left: -660px;
          }

          .dijia {
            width: 174px;
            height: 248px;
            position: absolute;
            top: 57%;
            right: -396px;
          }
        }

        .img4 {
          width: 840px;
          // height: 997px;
          // background-image: url("~@/assets/img/xinren/tiku.png");
          // background-size: 100% 100%;
          margin-top: 40px;

          .daina {
            width: 119px;
            height: 353px;
            position: absolute;
            top: 87%;
            left: -660px;
          }

          .title {
            position: relative;
            width: 100%;
            height: 132px;
            text-align: center;
            // top: 15px;
            // left: 340px;
            font-size: 36px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #fcd39d;
            background-image: url("~@/assets/img/xinren/z21148.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;

            span {
              position: relative;

              top: 15px;
              // left: 340px;
            }
          }

          .right {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            position: relative;
            // top: 130px;
            // left: 46px;
            background-image: url("~@/assets/img/xinren/z21150.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            padding: 30px;
            padding-bottom: 0;
            margin-top: -24px;

            .contentItem {
              float: left;
              width: 372px;
              height: 400px;
              background: linear-gradient(1deg, #fcf8f2 0%, #fef0db 100%);
              border-radius: 10px 10px 10px 10px;
              opacity: 1;
              cursor: pointer;
              margin: 0 22px 18px 0px;
              transition: all 500ms;
              padding: 15px;

              &:hover {
                transform: translateY(-10px);

                .imgClass {
                  transform: scale(1.1);
                }

                box-shadow: 0px 1px 7px 7px rgba(207, 207, 207, 0.1);
              }

              .imgItem {
                width: 340px;
                height: 195px;
                border-radius: 10px;
                overflow: hidden;
                opacity: 1;

                .imgClass {
                  width: 100%;
                  height: 195px;
                  transition: all 500ms;
                }
              }

              .item_content {
                width: 340px;
                position: relative;
                display: flex;
                padding: 2px 0px 0px;
                flex-direction: column;
                justify-content: space-between;
                height: 156px;

                .titleItem {
                  font-size: 24px;
                  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                  font-weight: 400;
                  color: #333333;
                  margin-top: 7px;
                  flex-shrink: 0;
                }

                .jiage {
                  position: relative;
                  top: 20px;

                  .ziliao {
                    width: 94px;
                    height: 30px;
                    background-image: url("~@/assets/img/xinren/lh155.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    border-radius: 21px 21px 21px 21px;
                    opacity: 1;
                    padding-left: 10px;
                    padding-top: 2px;

                    span {
                      width: 73px;
                      height: 19px;
                      font-size: 14px;
                      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                      font-weight: 400;
                      color: #a45900;
                    }
                  }

                  .new {
                    font-size: 28px;
                    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                    font-weight: bold;
                    color: #f23239;
                    margin-bottom: 5px;

                    .span1 {
                      font-size: 18px;
                      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                      font-weight: 400;
                      color: #f23239;
                    }

                    .span2 {
                      font-size: 13px;
                      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                      font-weight: 400;
                      color: #f23239;
                    }
                  }
                }

                .guoqibao {
                  width: 122px;
                  height: 51px;
                  background: #b6b6b6;
                  box-shadow: 0px 3px 10px 1px rgba(177, 177, 177, 0.5);
                  border-radius: 10px;
                  opacity: 1;
                  position: absolute;
                  left: 220px;
                  top: 130px;
                  padding-top: 6px;
                  padding-left: 11px;

                  span {
                    font-size: 26px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                  }
                }

                .baoming {
                  width: 122px;
                  height: 51px;
                  background: #f13239;
                  box-shadow: 0px 3px 10px 1px rgba(241, 50, 57, 0.5);
                  border-radius: 10px 10px 10px 10px;
                  opacity: 1;
                  position: absolute;
                  left: 220px;
                  top: 120px;
                  padding-top: 6px;
                  padding-left: 11px;

                  span {
                    font-size: 26px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                  }
                }
              }
            }

            .contentItem:nth-child(2n - 1) {
              margin-left: 6px;
            }

            .contentItem:nth-child(2n) {
              margin-right: 0px;
            }

            .shangyiye2 {
              width: 20px;
              height: 5px;
              background: #fff;
              position: relative;
              top: 416px;
              left: -444px;
              margin-left: 5px;
            }

            .xiayiye2 {
              width: 20px;
              height: 5px;
              background: #fff;
              position: relative;
              top: 416px;
              left: -414px;
            }
          }

          .bot {
            width: 100%;
            height: 71px;
            background-image: url("~@/assets/img/xinren/z21149.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-top: -25px;
          }

          .aoteman {
            width: 147px;
            height: 300px;
            position: absolute;
            top: 100%;
            right: -396px;
          }
        }
      }
    }

    .guize {
      width: 100%;
      height: 296px;
      background-image: url("~@/assets/img/xinren/guize.png");
      background-size: 100% 100%;
      display: flex;
      // justify-content: center;
      position: relative;
      margin: 0 auto;
      margin-top: 50px;
      padding-top: 114px;
      padding-left: 100px;

      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 140px;

        div {
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;

          // margin-bottom: 18px;
          // margin-bottom: 19px;
          div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            background: #ffffff;
            // border-radius: 3px 3px 3px 3px;
            opacity: 1;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #f87446;
            // clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            // -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            // clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            // border-radius: 50%;
            margin-right: 12px;
            position: relative;
            transform: rotate(45deg);
            overflow: hidden;
            border-radius: 3px;

            span {
              transform: rotate(-45deg);
            }
          }

          div::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

@keyframes move1 {
  20% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(20px, 0, 0);
  }
}

@keyframes move2 {
  20% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-20px, 0, 0);
  }
}

@keyframes move3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.swiper {
  width: 100%;
  height: 300px;
}

.swiper-container {
  overflow: hidden;
}
</style>
